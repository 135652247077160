import React from "react"
import { Link, graphql } from "gatsby"
import documentToReactComponents from "../components/RichTextRenderer"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  // const posts = data.allMarkdownRemark.edges

  console.log(data.allContentfulBlogPost.edges[0].node.body.json)
  const exampleDoc = documentToReactComponents({
    richTextJson: data.allContentfulBlogPost.edges[0].node.body.json,
  })
  console.log(exampleDoc)

  const posts = data.allContentfulBlogPost.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      <Bio />
      {/* {exampleDoc} */}
      {posts.map(({ node }) => {
        const title = node.title
        return (
          <article key={node.slug}>
            <header>
              <h3
                style={{
                  ...scale(1),
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link
                  style={{ boxShadow: `none`, color: "#827463" }}
                  to={node.slug}
                >
                  {title}
                </Link>
              </h3>
              <small>{node.publishDate}</small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.intro.childMarkdownRemark.html,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          body {
            json
          }
          title
          slug
          intro {
            childMarkdownRemark {
              html
            }
          }
          publishDate(formatString: "MMMM Do, YYYY")
        }
      }
    }
  }
`
